<template>
  <el-row class="back-center" type="flex">
    <el-col :span="isCollapse ? 1 : 3">
      <div class="back-logo" @click="handleLogoClick">
        <img src="../../assets/img/logo-new.jpg" alt=""/>
      </div>
      <back-sidebar @collapse-change="handleCollapseChange"></back-sidebar>
    </el-col>
    <el-col :span="isCollapse ? 23 : 21">
      <back-header></back-header>
      <div class="bot-screen">
        <div class="main-screen">
            <router-view></router-view>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import backHeader from "./common/header/backHeader";
import backSidebar from "./common/sidebar/backSidebar";
import Vue from "vue";
export default {
  name: "back",
  components: {
    backHeader,
    backSidebar
  },
  props: {
    msg: String,
  },
  data() {
    return {
      isCollapse: false
    };
  },
  methods: {
    handleLogoClick(){
      this.$router.push("/back/index");
    },
    handleCollapseChange(collapsed) {
      this.isCollapse = collapsed;
    }
  },
};
</script>
<style lang="less">
.back-center {
  height: 100%;
  .back-logo {
    height: 80px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 60%; 
    }
  }
  
}
</style>

<style lang="less" scoped>
.back-center {
  
  .bot-screen {
    width: 100%;
    height: calc(100% - 80px);
    background-color: #efefef;
    padding: 15px;
    overflow:auto;
  }
  .main-screen {
    background-color: #fff;
    min-height: 810px;
    margin: 0 auto;
    border: 1px solid rgba(230, 230, 230, 100);
  }
}

</style>