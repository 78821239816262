import { render, staticRenderFns } from "./backSidebar.vue?vue&type=template&id=09773180&scoped=true&"
import script from "./backSidebar.vue?vue&type=script&lang=js&"
export * from "./backSidebar.vue?vue&type=script&lang=js&"
import style0 from "./backSidebar.vue?vue&type=style&index=0&id=09773180&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09773180",
  null
  
)

export default component.exports